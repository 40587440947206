<template>
  <section>
    <div>
      <pm-Dialog class="xml-dialog" header="Por favor Aguarde! " v-model:visible="displayexemplo"
              :style="{width: '50vw'}" :modal="true">
         <p>Por favor não Recarregar a pagina enquanto o processo não terminar!</p>
         <pm-ProgressSpinner />
         <br><br>
        <pm-Button  label="Interromper Processo"  @click="interromper=true;"  />
      </pm-Dialog>
    </div>

    <div class="card" style="width: 100%; margin-top: 15px">
        <div class="card-body" style="padding-left: 25px; padding-right: 20px">
            <h3>Inserir Arquivos ao Banco</h3>

            <div class="p-fluid formgrid grid" >
              <div class="field col-12 md:col-8 lg:col-3">
                <label>Selecione o Modulo:</label>
                <select class="p-inputtext p-component" v-model="modulo"
                  style="appearance: revert !important;width: 100% !important;" >
                    <option value="" disabled selected> -- Escolha um modulo -- </option>
                    <option v-for="n in modulos" :key="n" :value="n.modulo" >{{n.modulo}}</option>
                </select>
              </div>

              <div class="field col-12 md:col-8 lg:col-3">
                <label>tipo arquivo:</label>
                <select class="p-inputtext p-component" v-model="tipo"
                  style="appearance: revert !important;width: 100% !important;" >
                    <option value="" disabled selected> -- Escolha um tipo -- </option>
                    <option value="xml" >XML</option>
                </select>
              </div>
            </div>

            <div class="col-md-12" v-if="modulo=='Alunos'" style="background-color: antiquewhite;border-radius: 4px;padding: 20px;margin-bottom: 15px;">
              <p>Xml do <b>{{modulo}}</b> deve conter as colunas.</p>
              <p style="font-weight: 600;">
                  NOMEALUNOA - SOBRENOMEALUNOA - SEXO -  NATURALIDADE - CORRAÇA - NOMECOMPLETODOPAI - NOMECOMPLETODAMÃE - PNE
              </p>

              <!--<pm-Button  label="Exibir Exemplo"  @click="displayexemplo=true;"  />

              <pm-Dialog header="XML exemplo:" v-model:visible="displayexemplo"
              :style="{width: '50vw'}" :maximizable="true" :modal="true">
                <div class="p-fluid formgrid grid">
                  <div class=" field col-12 md:col-12">
                     <pre>
                        {{'<?xml version="1.0" encoding="utf-8"?>'}}
                        {{'<root>'}}
                            {{'<worksheet name="Planilha1">'}}
                               {{' <Row index="1"> '}}
                                    {{'<NOMEALUNOA>Bernardo</NOMEALUNOA>'}}
                                    {{'<SOBRENOMEALUNOA>Araújo dos Santos</SOBRENOMEALUNOA>'}}
                                    {{'<DATADENASC.>03/14/2019</DATADENASC.>'}}
                                    {{'<NATURALIDADE>Campos dos Goytacazes RJ</NATURALIDADE>'}}
                                    {{'<SEXO>M</SEXO>'}}
                                    {{'<CORRAÇA/>'}}
                                    {{'<NOMECOMPLETODOPAI>Márcio Coelho dos Santos</NOMECOMPLETODOPAI>'}}
                                    {{'<NOMECOMPLETODAMÃE>Josani Rodrigues de Araújo dos Santos</NOMECOMPLETODAMÃE>'}}
                                    {{'<PNE>Não</PNE>'}}
                                {{'</Row>'}}
                            {{'</worksheet>'}}
                        {{'</root>'}}
                     </pre>
                  </div>
                </div>
              </pm-Dialog>-->
            </div>
            <div class="col-md-12" v-if="modulo=='Servidores'" style="background-color: antiquewhite;border-radius: 4px;padding: 20px;margin-bottom: 15px;">
              <p>Xml do <b>{{modulo}}</b> deve conter as colunas.</p>
              <p style="font-weight: 600;">
                  NOME - SOBRENOME - SEXO
              </p>
            </div>

            <div v-if="tipo=='xml'">
              <h5 class="card-title mb-2">
                  <p class="mb-3" >Carregue o arquivo XML: </p>

                  <pm-FileUpload  name="arquivos[]" :url="null" id="input" chooseLabel="Carregar" :auto="true" @select="defineArquivos" :customUpload="true" @uploader="processaArquivo" uploadLabel="Extrair dados" mode="basic" cancelLabel="Cancelar" accept="text/xml" >
                      <template #empty>
                          <p>Arraste e solte o arquivo aqui.</p>
                      </template>
                  </pm-FileUpload>
              </h5>

              <pm-Button  label="Confirmar Informações" class="p-button-success mb-3" icon="pi pi-save" iconPos="right" @click="enviaItens" v-if="arquivos.length>0" />

              <pm-DataTable  :globalFilterFields="['nome']" :value="arquivos" dataKey="id" :rowHover="true"
              ilterDisplay="menu" responsiveLayout="scroll" v-if="arquivos.length>0 && modulo != 'Servidores'" >

                <pm-Column field="nome" sortable header="Nome"  >
                </pm-Column>

                <pm-Column field="sobrenome"  header="SobreNome"  >
                </pm-Column>

                <pm-Column field="nascimento"  header="Nascimento"  >
                </pm-Column>

                <pm-Column field="naturalidade" sortable header="Naturalidade"  >
                </pm-Column>

                <pm-Column field="sexo" sortable header="Sexo"  >
                </pm-Column>

                <pm-Column field="cor_raca"  header="Cor/Raça"  >
                </pm-Column>

                <pm-Column field="nome_pai" sortable header="Pai"  >
                </pm-Column>

                <pm-Column field="nome_mae" sortable header="Mãe"  >
                </pm-Column>

                <pm-Column field="deficiencia" sortable header="Deficiente"  >
                </pm-Column>
              </pm-DataTable>


              <pm-DataTable  :globalFilterFields="['nome']" :value="arquivos" dataKey="id" :rowHover="true"
              ilterDisplay="menu" responsiveLayout="scroll" v-if="arquivos.length>0 && modulo == 'Servidores'" >

                <pm-Column field="nome" sortable header="Nome"  >
                </pm-Column>

                <pm-Column field="sobrenome"  header="SobreNome"  >
                </pm-Column>

                <pm-Column field="cpf"  header="cpf"  >
                </pm-Column>

                <pm-Column field="sexo" sortable header="Sexo"  >
                </pm-Column>

                <pm-Column field="escolaridade"  header="Escolaridade"  >
                </pm-Column>

                <pm-Column field="matricula" sortable header="matricula"  >
                </pm-Column>

                <pm-Column field="email" sortable header="email"  >
                </pm-Column>

                <pm-Column field="funcao" sortable header="funcao"  >
                </pm-Column>
              </pm-DataTable>
            </div>
        </div>
    </div>
  </section>
</template>

<script>

import { defineComponent } from 'vue'
import { Aluno } from "@/class/alunos.js";
import { Servidores } from "@/class/servidores.js";

export default defineComponent({
  components: {},
  props: {},
  data () {
    return {
      modulos:[{modulo:"Alunos"},{modulo:"Servidores"}],
      modulo:"Alunos",
      arquivos:[],
      selectedFile: null ,
      valido: false,
      tipo:"xml",
      displayexemplo: false,
      interromper:false,
    }
  },
  methods:{
    xmlToJson(xml) {
        // Create the return object
        var obj = {}, i, j, attribute, item, nodeName, old;

        if (xml.nodeType === 1) { // element
            // do attributes
            if (xml.attributes.length > 0) {
                obj["@attributes"] = {};
                for (j = 0; j < xml.attributes.length; j = j + 1) {
                    attribute = xml.attributes.item(j);
                    obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
                }
            }
        } else if (xml.nodeType === 3) { // text
            obj = xml.nodeValue;
        }

        // do children
        if (xml.hasChildNodes()) {
            for (i = 0; i < xml.childNodes.length; i = i + 1) {
                item = xml.childNodes.item(i);
                nodeName = item.nodeName;
                if ((obj[nodeName]) === undefined) {
                    obj[nodeName] = this.xmlToJson(item);
                } else {
                    if ((obj[nodeName].push) === undefined) {
                        old = obj[nodeName];
                        obj[nodeName] = [];
                        obj[nodeName].push(old);
                    }
                    obj[nodeName].push(this.xmlToJson(item));
                }
            }
        }
        return obj;
    },
    defineArquivos(event){
        var noxml = 0;
        var file = '';
        var i = 0;
        for (i=0; i<event.files.length; i++){
            file = event.files[i].name;
            if(file.indexOf('.xml')==-1){
                noxml+=1;
            }
        }
        if(noxml>0){
            this.$toast.error('O arquivo não possui extensão XML.');

        }
        else{
            this.selectedFile= event.files[0]
            this.valido=true
        }

    },
    async processaArquivo(dado){
      this.arquivos=[];
      if(this.valido){
          var that = this;
          if(this.modulo == "Alunos"){
            var reader = new FileReader();
            reader.onload = function(e) {
                if(e!=null){
                  try{
                    var readXml =e.target.result;
                    var parser = new DOMParser();
                    var xml = parser.parseFromString(readXml, "application/xml");
                    var doc = that.xmlToJson(xml);

                    var row = doc['root']['worksheet']['Row'];

                    if(row.length>0){
                      var i=0
                      var it={}
                      for (i=0; i<row.length; i++){
                        let defi = 0;

                        if(row[i].hasOwnProperty('PNE')){
                          if(row[i]['PNE']['#text'] == "Não" || row[i]['PNE']['#text'] == "NÃO"){defi = 0;}else{defi = 1;}
                        }

                        const regex = /\W|_/;
                        let datevalido = "";
                        if(row[i].hasOwnProperty('DATADENASC.')){
                          if(regex.test(row[i]['DATADENASC.']['#text'])){
                            var dataform = row[i]['DATADENASC.']['#text'].split('/');
                            //29/01/2010
                            datevalido = dataform[2]+'-'+dataform[1]+'-'+dataform[0];
                            //datevalido = "";
                            /*var dataform = row[i]['DATADENASC.']['#text'].toLocaleDateString('sv-SE');
                            datevalido =dataform;*/
                          }
                        }

                        // xml campo nome do aluno - variações possiveis
                        let aluno = " ";
                        if(row[i].hasOwnProperty('NOMEALUNOA')){
                          aluno = row[i]['NOMEALUNOA']['#text'];
                        }else if(row[i].hasOwnProperty('NOME')){
                          aluno = row[i]['NOME']['#text'];
                        }else if(row[i].hasOwnProperty('NOMEALUNO')){
                          aluno = row[i]['NOMEALUNO']['#text'];
                        }else if(row[i].hasOwnProperty('NOMECOMPLETODOAALUNOA')){
                          aluno = row[i]['NOMECOMPLETODOAALUNOA']['#text'];
                        }else{
                          aluno = " ";
                        }

                        //xml campo sobrenome - variações
                        let aluno_sobrenome = " ";
                        if(row[i].hasOwnProperty('SOBRENOMEALUNOA')){
                          aluno_sobrenome = row[i]['SOBRENOMEALUNOA']['#text'];
                        }else if(row[i].hasOwnProperty('SOBRENOME')){
                          aluno_sobrenome = row[i]['SOBRENOME']['#text'];
                        }else if(row[i].hasOwnProperty('SOBRENOMEALUNO')){
                          aluno_sobrenome = row[i]['SOBRENOMEALUNO']['#text'];
                        }else{
                          aluno_sobrenome = " ";
                        }

                        //xml campo sexo - variações
                        let sexo = "M";
                        if(row[i].hasOwnProperty('SEXO')){
                          sexo = row[i]['SEXO']['#text'];
                        }else{
                          sexo = "M";
                        }

                        //xml campo naturalidade - variações
                        let naturalidade = "";
                        if(row[i].hasOwnProperty('NATURALIDADE')){
                          naturalidade = row[i]['NATURALIDADE']['#text'];
                        }else{
                          naturalidade = " ";
                        }

                        //xml campo cor/raca - variações
                        let cor_raca = "";
                        if(row[i].hasOwnProperty('CORRAÇA')){
                          cor_raca = row[i]['CORRAÇA']['#text'];
                        }else if(row[i].hasOwnProperty('CORRACA')){
                          cor_raca = row[i]['CORRACA']['#text'];
                        }else{
                          cor_raca = " ";
                        }

                        //xml campo pai - variações
                        let nome_pai = "";
                        if(row[i].hasOwnProperty('NOMECOMPLETODOPAI')){
                          nome_pai = row[i]['NOMECOMPLETODOPAI']['#text'];
                        }else if(row[i].hasOwnProperty('PAI')){
                          nome_pai = row[i]['PAI']['#text'];
                        }else{
                          nome_pai = " ";
                        }

                        //xml campo mãe - variações
                        let nome_mae = "";
                        if(row[i].hasOwnProperty('NOMECOMPLETODAMÃE')){
                          nome_mae = row[i]['NOMECOMPLETODAMÃE']['#text'];
                        }else if(row[i].hasOwnProperty('MÃE')){
                          nome_mae = row[i]['MÃE']['#text'];
                        }else if(row[i].hasOwnProperty('MAE')){
                          nome_mae = row[i]['MAE']['#text'];
                        }else{
                          nome_mae = " ";
                        }

                        it={
                          'nome': aluno,
                          'sobrenome': aluno_sobrenome,
                          'nascimento': datevalido,
                          'naturalidade': naturalidade,
                          'sexo': sexo,
                          'cor_raca': cor_raca,
                          'nome_pai': nome_pai,
                          'nome_mae': nome_mae,
                          'deficiencia': defi,
                        };
                        that.arquivos.push(it);
                      }
                    }

                    if(that.arquivos.length==0){
                      that.$toast.error('Arquivo XML não correspondente.');
                    }

                  } catch (error) {
                    console.log(error)
                    that.$toast.error('Arquivo XML inválido ou corrompido.');
                  }
                }
            }

            if(this.selectedFile!=null){
                  reader.readAsText(this.selectedFile);
              }
              else{
                  this.$toast.error('Carregue um arquivo XML');
              }
          }
          if(this.modulo == "Servidores"){
            var reader = new FileReader();
            reader.onload = function(e) {
                if(e!=null){
                  try{
                    var readXml =e.target.result;
                    var parser = new DOMParser();
                    var xml = parser.parseFromString(readXml, "application/xml");
                    var doc = that.xmlToJson(xml);

                    var row = doc['root']['worksheet']['Row'];

                    if(row.length>0){
                      var i=0
                      var it={}
                      for (i=0; i<row.length; i++){

                        // xml campo nome  - variações possiveis
                        let servidor = " ";
                        if(row[i].hasOwnProperty('NOME')){
                          servidor = row[i]['NOME']['#text'];
                        }else if(row[i].hasOwnProperty('nome')){
                          servidor = row[i]['nome']['#text'];
                        }else if(row[i].hasOwnProperty('Nome')){
                          servidor = row[i]['Nome']['#text'];
                        }

                        //xml campo sobrenome - variações
                        let servidor_sobrenome = " ";
                        if(row[i].hasOwnProperty('SOBRENOME')){
                          servidor_sobrenome = row[i]['SOBRENOME']['#text'];
                        }else if(row[i].hasOwnProperty('sobrenome')){
                          servidor_sobrenome = row[i]['sobrenome']['#text'];
                        }else if(row[i].hasOwnProperty('Sobrenome')){
                          servidor_sobrenome = row[i]['Sobrenome']['#text'];
                        }

                        //xml campo sexo - variações
                        let sexo = "M";
                        if(row[i].hasOwnProperty('SEXO')){
                          if(row[i]['SEXO']['#text'] == 'FEMININO'){
                            sexo = "F";
                          }else if(row[i]['SEXO']['#text'] == 'MASCULINO'){
                            sexo = "M";
                          }else{
                            sexo = row[i]['SEXO']['#text'];
                          }
                        }else if(row[i].hasOwnProperty('sexo')){
                          sexo = row[i]['sexo']['#text'];
                        }else if(row[i].hasOwnProperty('Sexo')){
                          sexo = row[i]['Sexo']['#text'];
                        }else{
                          sexo = "M";
                        }

                        let cpf="";
                        if(row[i].hasOwnProperty('CPF')){
                          cpf = row[i]['CPF']['#text'];
                        }else{
                          cpf="";
                        }

                        let escolaridade = "ENSINO MÉDIO";
                        if(row[i].hasOwnProperty('ESCOLARIDADE')){
                          escolaridade = row[i]['ESCOLARIDADE']['#text'];
                        }else{
                          escolaridade="";
                        }

                        let matricula = "";
                        if(row[i].hasOwnProperty('MATRICULA')){
                          matricula = row[i]['MATRICULA']['#text'];
                        }else{
                          matricula ="";
                        }

                        let email= "";
                        if(row[i].hasOwnProperty('EMAIL')){
                          email = row[i]['EMAIL']['#text'];
                        }else{
                          email ="";
                        }


                        if(email == ""){
                          it={
                            'nome': servidor,
                            'sobrenome': servidor_sobrenome,
                            'sexo': sexo,
                            'cpf': cpf,
                            'escolaridade': escolaridade,
                            'matricula': matricula,
                            'deficiencia':0,
                          };
                          that.arquivos.push(it);
                        }else{

                          it={
                            'nome': servidor,
                            'sobrenome': servidor_sobrenome,
                            'sexo': sexo,
                            'cpf': cpf,
                            'escolaridade': escolaridade,
                            'matricula': matricula,
                            'email': email,
                            "funcao": 5,
                            'deficiencia':0,
                          };
                          that.arquivos.push(it);
                        }
                      }
                    }

                    if(that.arquivos.length==0){
                      that.$toast.error('Arquivo XML não correspondente.');
                    }

                  } catch (error) {
                    console.log(error)
                    that.$toast.error('Arquivo XML inválido ou corrompido.');
                  }
                }
            }

            if(this.selectedFile!=null){
                  reader.readAsText(this.selectedFile);
              }
              else{
                  this.$toast.error('Carregue um arquivo XML');
              }
          }
          else{
              this.$toast.error('Carregue um arquivo XML');
          }
          console.log(this.arquivos)
        }
    },



    async enviaItens(){
      if(this.modulo == "Alunos"){
          this.displayexemplo = true;
          for (let index = 0; index < this.arquivos.length; index++) {
            if(!this.interromper){
            try{
                let data = await Aluno.cadastrar(this.arquivos[index]);
                setTimeout(() => {
                  this.$vaToast.init({
                    message: "Aluno: "+this.arquivos[index]["nome"]+" "+this.arquivos[index]["sobrenome"]+" Salvo com sucesso!",
                    iconClass: 'fa-star-o',
                    position: 'top-right',
                    color: 'success',
                    duration: 2500,
                    fullWidth: false,
                  });
                },200);

            }catch(e){
                console.log(e.response.data.erro);
                this.$vaToast.init({
                    message: e.response.data.erro,
                    iconClass: 'fa-star-o',
                    position: 'top-right',
                    color: 'danger',
                    duration: 4000,
                    fullWidth: false,
                    //offsetX: 960,
                    //offsetY: 100,
                });
              }
            }else{
              break;
            }
          }
        this.arquivos = [];
        this.displayexemplo = false;
        this.interromper = false;
      }else if(this.modulo == "Servidores"){
          this.displayexemplo = true;
          for (let index = 0; index < this.arquivos.length; index++) {
            if(!this.interromper){
            try{
                let data = await Servidores.cadastrar(this.arquivos[index]);
                setTimeout(() => {
                  this.$vaToast.init({
                    message: "Servidor: "+this.arquivos[index]["nome"]+" "+this.arquivos[index]["sobrenome"]+" Salvo com sucesso!",
                    iconClass: 'fa-star-o',
                    position: 'top-right',
                    color: 'success',
                    duration: 2500,
                    fullWidth: false,
                  });
                },200);

            }catch(e){
                console.log(e.response.data.erro);
                this.$vaToast.init({
                    message: e.response.data.erro,
                    iconClass: 'fa-star-o',
                    position: 'top-right',
                    color: 'danger',
                    duration: 4000,
                    fullWidth: false,
                    //offsetX: 960,
                    //offsetY: 100,
                });
              }
            }else{
              break;
            }
          }
        this.arquivos = [];
        this.displayexemplo = false;
        this.interromper = false;
      }
    }
  },
  async created() {
    window.onbeforeunload = function() {
      return "Dude, are you sure you want to leave? Think of the kittens!";
    }
  },
})

</script>

<style>
  .xml-dialog .p-dialog-header .p-dialog-header-icons{
     display:none;
  }
</style>
